export default function TextField({label='', name, ph, children, fieldStyle, controlStyle, addons,  error, ...rest}) {
  return (
    <div className={`${fieldStyle} field`}>
      <label className="label is-left">{label || name}</label>
      <div className={`${controlStyle} control`}>
        <input className="input" type="text" placeholder={ph} {...rest}/>
        {children}
      </div>
      { addons && addons }
      {error && <p className="help is-danger">This field is required.</p>}
    </div>  
  )
}