import Select from "./Select";
import useRouter from '../../context/Routing'
import React from "react";
import _ from 'underscore'

export default function SmartSelect({data, ...rest}) {
  const router = useRouter();
  const [opts, setOpts] = React.useState([])

  React.useEffect(() => {
    if (data && router?.itemData) {
      const items = _.flatten(data && data.map((sch) => router.itemData[sch] || []) || [])
      
      setOpts(items.map((item, i) => 
        <option key={`${i}-${item.id}`}value={item.id}>
          {item.name || item.id}
        </option>
      ) )
    }
  }, [data, router?.itemData])

  return (
    <Select {...rest}> 
      <option></option>
      { opts }
    </Select>
  )
}