import React from "react"

const BASIC_OPTS = [1,2,3,6,9]
const DUR_OPTS = ['Day', 'Week', 'Month', 'Year']

export default function TimeFrame({onChange, value}) {
  const [duration, setDuration] = React.useState('0')
  const [val, setVal] = React.useState(null)
  const [adv, setAdv] = React.useState(false)


  React.useEffect(() => {
    if (!value) return; 

    const [amt, dur] = value.split('/')
    if (amt === val && dur === duration) return;

    Promise.all([
      setAdv(!BASIC_OPTS.includes(parseInt(amt))),
      setDuration(dur),
      setVal(amt),
    ])
  }, [value])

  const opened = duration != '0' 

  const buttonClass = (as) => `button ${val == as ? 'is-info is-selected' : ''}`

  const toggleAdv = () => setAdv(!adv)

  const updateInterval = (amt) => {
    if (amt < 1) return

    Promise.all([
      setVal(amt),
      onChange('interval', `${amt}/${duration}`)
    ])
  }

  const updateDuration = (dur) => {
    let int = (dur != '0' && val === null) ? 1 : val
    Promise.all([
      setDuration(dur),
    
      onChange('interval', dur == 0 ? false : `${int}/${dur}` )
    ])
  }

  const onClick = (e) => updateInterval(e.target.innerHTML)

  const intervalOptions = (
      <div className='field is-horizontal'>
        <div className="field-label is-normal">
          <label className="label">
            Interval
          </label>
        </div>
        <div className="field-body">
          <div className="field has-addons is-justify-content-center">
            <div className="control buttons has-addons">
              { !adv && BASIC_OPTS.map((i) => 
                <button key={`ib${i}`}onClick={onClick} className={buttonClass(i)}>{i}</button>
              )}
              { adv && <input type="number" defaultValue={val} value={val}
                        className={`input ${BASIC_OPTS.includes(val) ? '' : 'is-info'}`}
                        onChange={(e) => updateInterval(e.target.value)}/>
                      } 
            </div>
            <div className="control ">
              <button className="button" onClick={toggleAdv}>{adv ? 'Simple' : 'More'}</button>
            </div>
          </div>
        </div>
      </div>
  )
  
  return (
    <React.Fragment>
      <div className='field is-horizontal'>
        <div className="field-label is-normal">
          <label className="label">
            Repeated
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select onChange={(e) => updateDuration(e.target.value) } defaultValue={duration} value={duration}>
                  <option value='0'>Never</option>
                  {DUR_OPTS.map((day) => <option key={day}>{day}</option>)}
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
      { opened && intervalOptions }
    </React.Fragment>
  )
}