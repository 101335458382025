import Viewer from "./components/Viewer";
import Navigator from "./components/Navigator";
import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from './context/Auth'
import { RoutingProvider } from "./context/Routing";
import { IngressProvider } from "./context/Ingress";

import 'bulma/css/bulma.css'
import 'bulma-switch/dist/css/bulma-switch.min.css'

import './styles/index.css';
import Spacer from "./components/shared/Spacer";

export default function Plutusuit() {

  return (
    <IngressProvider>
      <RoutingProvider>
          <AuthProvider>
            <ErrorBoundary>
              <Navigator>
                <Spacer size={window.cordova ? '4' : '1'} />
                <Viewer />
              </Navigator>
              <Spacer size={window.cordova ? '6' : '1'} />
            </ErrorBoundary>
          </AuthProvider>
      </RoutingProvider>
    </IngressProvider>
  )
}