export default function FieldInput({label='', children, fieldStyle='', controlStyle='', buttons, error, tip, required, ...rest}) {
  return (
    <div className={`${fieldStyle} field is-horizontal`}>
      <div className="field-label is-normal">
        <label className="label">
          {label}
        </label>
        { required && 
            <p className={`help ${error ? 'is-danger' : 'has-text-grey-light'}`}>
              Required
            </p>
          }
      </div>
      <div className="field-body">
        <div className={!!buttons ? "field has-addons" : "field"}>
          <div className={`${controlStyle} control is-expanded`}>
            <input className="input" type="text" {...rest}/>
            { children }
          </div>
          { buttons }
          { (error || tip) && 
            <div className={`help ${error ? 'is-danger' : 'has-text-grey-light'}`}>
              { error || tip }
            </div>
          }
        </div>
      </div>
    </div>
  )
}