import icons from '../../data/icons'
import React from 'react';

export default function IconText({text, icon, iconStyle='', textStyle='',children}){
  const iconFa = React.useCallback(() => {
    return icons[icon] || 'circle-xmark'
  },[icon]);

  return (
    <span className="icon-text">
      { children }
      <span className={`icon ${iconStyle}`}>
        <i className={`fas fa-${iconFa()}`}></i>
      </span>
      {text &&  <span className={textStyle}>{text}</span>}
    </span>
  )
}