import useIngress  from '../../context/Ingress';

export default function NavLink({target, callback, children, ...rest}) {
  const ingress = useIngress()
  const handler = () => {
    const path = ingress.paths[target] 
    if (path && path instanceof Function) path()
    if (callback && callback instanceof Function) callback()
  }

  return (
    <a {...rest} onClick={handler}>{children}</a>
  )
}