import React from "react";
import _ from "underscore";
import Link from '../components/shared/Link'
import routesData from '../data/routes.json'
import Routing from '../utils/routes'

const IngressContext = React.createContext({});

export const parseRoutes = () => JSON.parse(JSON.stringify(routesData))

export const TraverseRoutes = (targetPath, routes) => {
  let foundRoute = []
  // console.log(JSON.stringify(routes))
  const iterator = (route) => {
    const valid = () => foundRoute.push(route)

    route.exact ? route.path === targetPath ? valid() : null
      : !!route.path.match(targetPath) ? valid() : null
  }

  _.each(routes, iterator, foundRoute)
  const selRoute = foundRoute[0]

  return selRoute
}

function useIngress() {
  const [isPending, startTransition] = React.useTransition();
  const [path, setPath] = React.useState('');
  const [paths, setPaths] = React.useState(undefined);
  const [view, setView] = React.useState()
  const [routes, setRoutes] = React.useState(undefined);
  const [mappings, setMappings] = React.useState(null)

  const router = Routing();
  const getSchema = (key) => router.find_klass(mappings, key)
  const getSchemaName = (key) => router.find_name(mappings, key)

  const nav = (givenPath) => {
    if (isPending) return

    startTransition(() => {
      Promise.all([
        setView(TraverseRoutes(givenPath, parseRoutes().routes)),
        setPath(givenPath),
      ])
    })
  }

  return {
    isPending,
    path,
    view,
    routes,
    paths,
    mappings,
    router,
    getSchema,
    getSchemaName,
    initiateRoutes() {
      return new Promise((res) => {
        if (!mappings) setMappings(router.initialize)

        if (routes === undefined)
          setRoutes(parseRoutes().routes)
        if (paths === undefined)
          setPaths(
            Object.fromEntries(
              _.map(parseRoutes().routes,
                (r) => [r.path.substr(1) || 'home', () => nav(r.path)]
              )
            )
          )
        if (view === undefined)
          nav('/')
        res()
      })
    },
    clearHistory() {
      return new Promise((res) => {
        // setHistory([]) 
        setRoutes(parseRoutes().routes)
        nav('/')
        res()
      })
    },
    nav,
    setView,
    link(title = '', classes = '', action, callback) {
      return <Link
        classes={classes}
        onClick={() => new Promise((res) => res(action && action())).then(() => callback && callback())}
        children={title}
      />
    }
  }
}

/* Attach to Top level components i.e.
  import { IngressProvider } from IngressProvider
  <IngressProvider><App /></IngressProvider>
*/
export function IngressProvider({ children }) {
  const ingress = useIngress();

  return <IngressContext.Provider value={ingress}>
    {children}
  </IngressContext.Provider>;
}

/* Consumed by children components i.e.
  import useIngress from Ingres
  const ingress = useIngress()
*/
export default function IngressConsumer() {
  return React.useContext(IngressContext);
}
