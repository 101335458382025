import * as React from "react";
import _ from 'underscore'
import { saveState, loadState } from '../utils/localStorage'

const AuthContext = React.createContext(null);

const STOR_KEY = 'userData'

function useAuth() {
  const [session, setSession] = React.useState(false);
  const [userData, setUserData] = React.useState(false);
  const [isPending, startTransition] = React.useTransition();

  return {
    session,
    userData,
    isPending,
    loadPrevSession() {
      return new Promise((res) => {
        startTransition(() => {
          const info = loadState(STOR_KEY)
          if (info) {
            const valid = info.session && info.uid
            setSession(valid)
            setUserData({...info})
          } else {
            setSession(false)
            setUserData(false)
          }
          res(!!info)
        })
      })
    },
    setMessage(msg) {
      return new Promise((res) => {
        startTransition( () => {
          setUserData({...userData, message: msg, messageType: 'is-success'})
          res()
        })
      })
    },
    setErrorMessage(msg) {
      return new Promise((res) => {
        startTransition( () => {
          setUserData({...userData, message: msg, messageType: 'is-danger'})
          res()
        })
      })
    },
    startSession(info) {
      return new Promise((res) => {
        startTransition( () => {
          setSession(true)
          setUserData({...info})
          saveState(STOR_KEY, info)
          res()
        })
      })
    },
    endSession() {
      return new Promise((res) => {
        startTransition( () => {
          setSession(false)
          setUserData({})
          saveState(STOR_KEY, {})
          saveState('itemData', {})
          res()
        })
      })
    },
    clearMessage() {
      return new Promise((res) => {
        startTransition( () => {
          setUserData(_.omit(userData, ['message', 'messageType']))
          res()
        })
      })
    }
  };
}

export function AuthProvider({ children }) {
  const session = useAuth();

  return <AuthContext.Provider value={session}>
    {children}
  </AuthContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(AuthContext);
}