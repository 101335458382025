import React, { Suspense } from 'react'
import _ from 'underscore';
import useAuth from '../context/Auth'
import useIngress from '../context/Ingress'
import LoginView from '../views/LoginView';
import Loading from './shared/Loading';

export default function Viewer() {
  const { session } = useAuth();
  const [ViewComponent, setViewComponent] = React.useState(undefined)
  const ingress = useIngress()
  const [cache, setCache] = React.useState({})

  React.useEffect(() => {
    ingress.initiateRoutes()
  }, [])
  
  React.useEffect(() => {
    const viewName = ingress.view?.view
    if(viewName) {
      if (cache[viewName]) 
        new Promise((res) => res(setViewComponent(cache[viewName])))
      else {
        const loaded = React.lazy(async () => {
          try { return await import('../views/'+viewName) }
          catch(e){ 
            console.log(`Unable to load ${viewName}: ${e}`) 
            return null
          }
        })
        if(loaded) setCache({...cache, [viewName]: loaded})
        setViewComponent(loaded)
      }
    }
      // setViewComponent(React.createElement(ingress.view.view, ingress.view))
    // ingress.renderView(ingress.view)
  }, [ingress.view])

  // if (!(ViewComponent)){
  //   return <LandingView title='Unknown view'  children={<p>{JSON.stringify(Page)}</p>}/>
  // }
  // if (!(Page?.component))
  //   return <LandingView title='Unknown view'  route={route}  children={<p>{JSON.stringify(Page)}</p>}/>

  // Validate session and "show" redirection
  // TODO: Wrap in a Boundry and Render loginView then children
  /*

    <Protected redirect={ingress.view.redirect}>
      <ViewComponent />
    <Protected>
  */

  const view = (ingress.view?.private && !session) ? 
    <LoginView {...ingress.view} title='Authentication Failure' /> :
    ViewComponent ? <ViewComponent {...ingress.view}/> : <div/>

  return (
    <Suspense fallback={<Loading/>}>
      { view } 
    </Suspense>
  );
}
