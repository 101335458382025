import React from 'react'
import _ from 'underscore'
import View from "./View";
import useAuth from "../context/Auth";
import useIngress from '../context/Ingress'
import useRoute from '../context/Routing'
import Form from '../components/Form';
import ErrorBox from '../components/shared/ErrorBox';
import Loading from '../components/shared/Loading';
import parser from '../utils/parser'
import * as actions from '../actions/index'
import schema from '../schemas/login.json'
import AckModal from "../components/shared/AckModal";

export default function LoginView({title}) {
  const [modal, setModal] = React.useState()

  const authContext = useAuth();
  const auth = actions.auth
  const ingress = useIngress();
  const data = useRoute()
  const [suppress, setSuppress] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const closeModal = () => setModal(false)
  const openModal = (txt) => setModal(txt)

  React.useEffect(() => {
    if (authContext.session) return
    if (authContext.loadPrevSession())
      ingress.paths.dashboard()
  }, [])

  const performLogin = async () => {
    if (!data) return
    const [result, errs] = parser(data.formData, schema)

    if (!result) {
      // console.log('validation failed')
      return new Promise((res) => {
        data.setErrors(errs)
        res()
      })
    }

    setLoading(true)

    auth(data.formData, (response) => {
      Promise.all([
        authContext.startSession(response.data),
        data.clear(),
        setLoading(false)
      ]).finally(ingress.paths.dashboard)
    }, (err) => {
      setSuppress(false)
      setLoading(false)
      const errorMessage = _.get(err, ['response', 'data', 'error'])
      openModal(errorMessage)
      data.setErrors([[
        'username',
        errorMessage
      ]])
    })
  }

  return (
    <View title='Plutusuit'>
      <h1 className="subtitle">Log in</h1>
      <ErrorBox errors={data?.errors} suppressErrorMessages={suppress} />
      <Form schema={schema} onChange={data?.setFormData} data={data?.formData} errors={data?.errors}>
        <div className="control mt-6">
          {loading && <Loading label='Entering... ' />}
          {!loading &&
            <div className="buttons is-expanded is-justify-content-space-evenly">
              {ingress.link('Sign Up', 'button is-flex', data?.clear, ingress.paths.signup)}
              {ingress.link('Submit', 'button  is-primary is-flex', performLogin)}
            </div>
          }
        </div>
      </Form>
      {modal && <AckModal title={title} text={modal} onClose={closeModal} />}
    </View>
  );
};