import _ from "underscore"
import Ajv from "ajv"

const ajv = new Ajv({strictSchema: false, coerceTypes: true, useDefaults: true}) // options can be passed, e.g. {allErrors: true}
ajv.addFormat("identifier", /^a-z\$_[a-zA-Z$_0-9]*$/)
ajv.addVocabulary(["hidden", "readonly"])
ajv.addKeyword(['attrs', 'component', 'as', ])

export const formatName = (word, len) => word && word.length > len ? word.substring(0,len)+'...' : word

export function capitalize(string) {
  if (!string) return 
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function validateData(data, schema){
  const validator =  ajv.compile(schema)
  if (Object.keys.length > 0)
    data = Object.fromEntries(Object.entries(data).map(([key, val]) => [key, val === "" ? undefined : val]));
  
  const results = validator(data)

  let errors = null
  if (!results) {
    errors = validator.errors.map((err) => {
      return [(_.get(err,['params', 'missingProperty']) || _.get(err,'instancePath').substring(1)), _.get(err,['message'])]
    })
  }

  return [results, errors]
}

export function moneyFromInt(value){
  // return Number(Math.round((value/100)+'e2')+'e-2')
  return parseInt(value)
}

export function moneyToInt(value){
  // return parseInt(value * 100.0)
  return parseInt(value)
}

export function timeExplained(interval){
  if(!interval || interval.length < 2) return 'Once'

  const [time, duration] = interval.split('/')

  if(!time || !duration) return 'Once'

  if (time == 1) return `Every ${duration}`

  return `Every ${time} ${duration}s`
}