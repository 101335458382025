import React from "react";
import View from "../views/View";
import NavLink from "./shared/NavLink";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.clearState = this.clearState.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  clearState() {
    this.setState({
      error: null,
      errorInfo: null
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <View title='Something went wrong'>
          <NavLink target="dashboard" callback={this.clearState}>
            Go Home
          </NavLink>
          <details className="error-box">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </View>
      )
    }
    return this.props.children;
  }
}
