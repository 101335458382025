const icons = {
  'deposit': 'circle-dollar-to-slot',
  'expense': 'file-invoice-dollar',
  'bank': 'piggy-bank',
  'transaction': 'receipt',
  'category': 'tag',
  'dashboard': 'home',
  'projection': 'money-bill-trend-up',
  'profile': 'gear',
  'auth': 'right-from-bracket',
  'loading': 'circle-notch fa-pulse fa-2x',
  'spinner': 'asterisk fa-pulse',
  'desc': 'caret-up fa-2x',
  'asc': 'caret-down fa-2x',
}

export default icons