import _ from 'underscore'
import moment from 'moment'
import settings from '../data/settings'

export const dateFormat = 'YYYY-MM-DD'

export function upcomingScheduled(scheduled) {
  const end = moment().add(settings.upcomingDays, 'days')
  return _.sortBy( _.filter(scheduled, (i) => moment(i.due).isBefore(end)),'due')
}

export function recentTransactions(transactions) {
  const start = moment().subtract(settings.recentDays, 'days')
  return _.sortBy( _.filter(transactions, (i) => moment(i.date).isAfter(start)),'date').reverse()
}

// Moves the due date of the item to the next date according to its schedule
export function advanceScheduleDate(interval, due) {
  if (!interval) return null

  const curDate = moment(due)
  const [amt, time] = interval.split('/')
  if (!amt || !time) return null

  return curDate.add(amt, time).format(dateFormat)
}

// Iterate through all expenses that have a due date before end date
// Also must have the same bank id
// Do the same for deposits
// sort the final result.
export function fincast(bank_id, data, endDateStr) {
  const now = moment().format(dateFormat)
  const endDate = moment(endDateStr)
  const condition = (schedule) => {
    return schedule.bank_id === bank_id && (schedule.due || schedule.date)
      && moment(schedule.due || schedule.date).isSameOrBefore(endDate)
  }
  const expenses = _.filter(data.expense, condition)
  const deposits = _.filter(data.deposit, condition)
  const transacs = _.filter(data.transaction, condition).map(
    (t) => tranactionFromExpense(t, moment(t.date).format(dateFormat))
  )
  let items = _.sortBy(
    transacs.concat(forecast(deposits.concat(expenses), endDate)).concat({
      today: true, 
      order: parseInt(`${moment(now).format('x')}0`),
      name: `Today's Date ${moment(now).format('MM/DD')}`,
      amount: 0
    }),
    'order')

  items = items.sort( (x, y) =>  x.order - y.order || Math.abs(y.amount) - Math.abs(x.amount) );

  let bala = 0;
  return items.map((i) => {
    const {  amount, type } = i
    bala += Math.abs(parseInt(amount)) * parseInt(type || 1)
    return { ...i, balance: bala }
  })
}

// create and add possible transaction to an array.
// while using the start date equal to expense due date
// add the reoccuring amount until the due date is greater than end date
// or until the due date is greater than it's own scheduled end date
// Sort the end result by due date.
function forecast(schedules, endDate) {
  let arr = []
  schedules.forEach((exp) => {
    const money = parseInt(exp.amount)
    const fore = {
      ...exp,
      amount: exp.id[0] === 'e' ? -1 * money : money
    }
    let current = moment(exp.due)
    let sEnd = moment(!!exp.end ? exp.end : endDate.toDate())
    arr.push(tranactionFromExpense(fore, current.format(dateFormat)))
    const { interval } = exp
    if (interval) {
      const [amt, time] = interval.split('/')
      if (amt && time) {
        current = current.add(amt, time)
        while (current.isSameOrBefore(endDate) && current.isSameOrBefore(sEnd) ) {
          arr.push(tranactionFromExpense(fore, current.format(dateFormat)))
          current = current.add(amt, time)
        }
      }
    }
  })
  return _.sortBy(arr, 'date')
}

function tranactionFromExpense(expense, date) {
  const { id, name, amount, bank_id, type } = expense
  const mul = type === undefined ? id[0] === 'e' ? -1 : 1 : parseInt(type)
  return {
    order: parseInt(`${moment(date).format('x')}${mul*-1+2}`),
    type: mul,
    name,
    amount: Math.abs(amount) * mul,
    date,
    bank_id,
    schedule_id: id
  }
}

export default forecast;