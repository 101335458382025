export default function View({title, subtitle, children}) {
  return (
    <section className="hero is-medium ">
      <div className="hero-body">
        <div className="container has-text-centered box has-background-white-ter is-rounded">
          <h1 className='is-size-3 mb-4 title'>{title}</h1>
          { subtitle && <h2 className="subtitle is-size-6 has-text-grey">{subtitle}</h2> }
          { children }
        </div>
      </div>
    </section>
  )
}