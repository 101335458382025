import React from 'react'

export default function ErrorBox({ 
  errors, 
  suppressErrorMessages=false, 
  message='There was an error submitting the form.'
}) {
  if ( !errors || errors.length <= 0 )
    return <React.Fragment/>

  return (
    <div className="box has-background-danger-light has-text-danger">
      { !suppressErrorMessages && errors.map((err) => 
        <p key={err[0]}> {err[1]} </p>
      )}
      { suppressErrorMessages && message }
    </div>
  )
}