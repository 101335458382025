import FieldInput from "./FieldInput"

export default function MoneyField({ controlStyle, label, name, ...rest}) {
  return (
    <FieldInput label={label || name} controlStyle={`${controlStyle} has-icons-left has-icons-right`} {...rest}>
      <span className="icon is-small is-left">
        <i className="fas fa-dollar-sign"></i>
      </span>
      <span className="icon is-small is-right">
        .
        <i className="fas fa-0"/>
        <i className="fas fa-0"/>
      </span>
    </FieldInput>
  )
}