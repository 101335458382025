import React from "react"

export default function Select({ name, label, fieldStyle="field has-addons", children, required, error, ...rest }) {
  return (
    <React.Fragment>
      <div className='field is-horizontal'>
        <div className="field-label is-normal">
          <label className="label">
            {label || name}
          </label>
          { required && 
            <p className={`help ${error ? 'is-danger' : 'has-text-grey-light'}`}>
              Required
            </p>
          }
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select {...rest}>
                  {children}
                </select>
                {error && <p className="help is-danger">This field is required.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}