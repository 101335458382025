import React from "react";

export default function Toggle({name, label, fieldStyle='field is-grouped is-align-content-flex-end', ...rest})  {
  return (
    <React.Fragment>
      <div className='field is-horizontal'>
        <div className="field-label is-normal">
          <label className="label">
            { label || name }
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control is-expanded">
              <input id={`switch-${name}`} type="checkbox" name={`switch-${name}`} className="switch is-responsive" {...rest} />
              <label className="is-size-6 has-text-weight-semibold" htmlFor={`switch-${name}`}>{rest.value ? 'Yes' : 'No'}</label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
