import bank from '../schemas/bank.json'
import deposit from '../schemas/deposit.json'
import expense from '../schemas/expense.json'
import transaction from '../schemas/transaction.json'
import category from '../schemas/category.json'

function Routing() {
  const addResource = (mappings, name, klass) => {
    mappings.str = {
      ...(!mappings ? {} : mappings.str),
      [name[0]]: name
    }
    mappings.klass = {
      ...(!mappings ? {} : mappings.klass),
      [name[0]]: klass
    }
    return mappings
  }

  const locate = (data, as) => data && data[as] ? data[as] : {}

  return {
    initialize(){
      let data = addResource({}, 'bank', bank)
      data = addResource(data, 'deposit', deposit)
      data = addResource(data, 'expense', expense)
      data = addResource(data, 'transaction', transaction)
      data = addResource(data, 'category', category)
      return data      
    },

    find_klass(data, key){
      return locate(data, 'klass')[key]
    },
    
    find_name(data, key){
      return locate(data, 'str')[key]
    }
  }
}

export default Routing