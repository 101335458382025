import React from 'react';
import ReactDOM from 'react-dom/client';
import Plutusuit from './Plutusuit';

const renderReactDom = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <div className='mainbox'>
        <Plutusuit />
      </div>
    </React.StrictMode>
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}
