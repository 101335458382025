// Holds the form data and item cache

import React from "react";
import _ from "underscore";
import parser from '../utils/parser'
import { saveState, loadState } from '../utils/localStorage'

const RoutingContext = React.createContext();
const STOR_KEY = 'itemData'

function useRouting() {
  const [formData, setData] = React.useState({});
  const [errors, setErrors] = React.useState(null);
  const [itemData, setItemData] = React.useState({});

  React.useEffect(() => {
    if (Object.keys(itemData).length < 1)
      setItemData(loadState('itemData'))
  },[])

  return {
    formData,
    itemData,
    errors,
    setErrors,
    findItem(schemaName, id) {
      return _.find(itemData[schemaName], ((item) => item.id == id ))
    },
    deleteItem(schema, id) {
      if (!id) return
      const curItems = itemData && itemData[schema] ? itemData[schema] : []
      const newItems = {
        ...itemData,
        [schema]: [
          ...(_.filter(curItems, (i) => i.id !== id))
        ]
      }
      return new Promise((res) => {
        setItemData(newItems)
        try {
          saveState(STOR_KEY, newItems)
        } catch (error) {
          console.log('error saving '+ JSON.stringify(error))
        }
        setData({})
        res()
      })
    },
    validateFormData(json) {
      const [result, errs] = parser(formData, json)
      return new Promise((res) => {
        setErrors(!result ? errs : null)
        res(result)
      })
    },
    cacheDataRecord(schema, record){
      // Check if the record already exists and remove
      const curItems = itemData && itemData[schema] ? itemData[schema] : []
      const schemaItems = _.filter(curItems, (i) => i.id !== record.id) || []
      // Rebuild and save
      return new Promise((res) => {
        setItemData({
          ...(itemData || []),
          [schema]:[
            ...schemaItems,
            record
          ]
        })
        res()
      })
    },
    setDataRecords(schema, records){
      return new Promise((res) => {
        setItemData({
          ...(itemData || []),
          [schema]:[
            ...(records || [])
          ]
        })
        res()
      })
    },
    setFormData(k,v) {
      return new Promise((res) => {
        setData({...formData, [k]: v})
        res()
      })
    },
    loadForm(data) {
      setData({...data})
    },
    clear() {
      return new Promise((res) => {
        setData({})
        setErrors(null)
        res()
      })
    },
    emptyState() {
      return new Promise((res) => {
        saveState(STOR_KEY, {})
        setItemData({})
        res()
      })
    }
  }
}

// Used at top level component
export function RoutingProvider({ children }) {
  const routing = useRouting();

  return <RoutingContext.Provider value={routing}>
    {children}
  </RoutingContext.Provider>;
}

// Used on children components 
export default function RoutingConsumer() {
  return React.useContext(RoutingContext);
}
