import React from 'react'
export default function AlertMessage({ 
  message, 
  style='',
  onClear
}) {
  if ( !message )
    return <React.Fragment/>

  const btnClose = React.useCallback(() => {
    return  <span className={`is-large delete tag is-rounded has-background${!!style ? `-${style.split('-')[1]}` :''}-dark`}/>
  },[style],);

  const textMessage = React.useMemo(() => typeof(message) === 'object' ? JSON.stringify(message) : message, [message])

  return (
    <div className={`notification is-clickable is-rounded bottomout ${style}`} onClick={onClear}>
      { btnClose() }
      <div className="is-size-6 has-text-centered has-text-weight-medium is-family-primary">{ textMessage }</div>
    </div>
  )
}